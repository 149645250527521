<script setup>
import { ref } from "vue";
import { useAuthenticationStore } from "@/stores/authentication";
import { deleteUser } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { firestoreClient, firebaseAuth } from "@/firebaseSetup";
import * as Sentry from "@sentry/vue";
import { useRouter } from "vue-router";

const authStore = useAuthenticationStore();
const router = useRouter();

const loading = ref(false);
const error = ref(null);

const requestAccountDeletion = async () => {
  if (
    confirm(
      "Are you sure you want to delete your account? This action cannot be undone.",
    )
  ) {
    loading.value = true;
    error.value = null;
    try {
      const user = firebaseAuth.currentUser;
      if (!user) throw new Error("No authenticated user found");

      // Create deletion request in Firestore
      await setDoc(doc(firestoreClient, "deletion_requests", user.uid), {
        userId: user.uid,
        requestedAt: new Date().toISOString(),
        status: "pending",
      });

      // Delete Firebase Auth user
      await deleteUser(user);

      // Clear local auth state
      authStore.user = null;

      Sentry.captureMessage("User account deletion requested successfully");

      // Redirect to login page
      router.push("/login");
    } catch (err) {
      console.error("Error requesting account deletion:", err);
      Sentry.captureException(err);
      error.value =
        "Failed to request account deletion. Please try again or contact support.";
    } finally {
      loading.value = false;
    }
  }
};
</script>

<template>
  <div class="settings-view container">
    <h1>Settings</h1>
    <section>
      <h2>Account Management</h2>
      <div v-if="loading">Processing...</div>
      <div v-if="error" class="error">{{ error }}</div>
      <div class="danger-zone">
        <h3>Danger Zone</h3>
        <p>
          Requesting account deletion will initiate the process to permanently
          remove all your data from our system.
        </p>
        <button @click="requestAccountDeletion" class="button">
          Request Account Deletion
        </button>
      </div>
    </section>
  </div>
</template>

<style scoped>
.danger-zone {
  margin-top: var(--spacing-lg);
  padding: var(--spacing-md);
  border: 1px solid var(--color-error, red);
  border-radius: var(--border-radius);
}

.danger-zone h3 {
  color: var(--color-error, red);
}

.danger-zone .button {
  background-color: var(--color-error, red);
  color: var(--white-absolute);
  margin-top: var(--spacing-md);
}
</style>
