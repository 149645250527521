<script setup>
import { ref } from "vue";
import LegalDocument from "@/components/LegalDocument.vue";
import rawPrivacyPolicy from "@/assets/privacy-policy.html?raw";

const privacyPolicyContent = ref(rawPrivacyPolicy);
</script>

<template>
  <LegalDocument :content="privacyPolicyContent" />
</template>
