<script setup>
import { FIRESTORE_INSTA_AUTHORS, FIRESTORE_INSTA_COMMENTS } from "@/constants";
import { firestoreClient } from "@/firebaseSetup";
import { useAuthenticationStore } from "@/stores/authentication";
import * as Sentry from "@sentry/vue";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { defineEmits, onMounted, ref, computed } from "vue";
import CommentThread from "./CommentThread.vue";

const emits = defineEmits(["self-destruct"]);

const props = defineProps(["cluster"]);
const comments = ref([]);
const loading = ref(true);
const mediaUrl = ref("");
const n_comments = ref(0);
const authorStats = ref({});

const authenticationStore = useAuthenticationStore();
const showAllComments = ref(false);

async function fetchAuthorStats(authorId) {
  await authenticationStore.waitForInitialization();
  const authenticatedInstaUserId =
    authenticationStore.authenticatedInstaBusinessAccountId;
  const joinedAuthorId = `${authenticatedInstaUserId}:${authorId}`;
  const authorDocRef = doc(
    firestoreClient,
    FIRESTORE_INSTA_AUTHORS,
    joinedAuthorId,
  );

  try {
    const authorDoc = await getDoc(authorDocRef);
    if (authorDoc.exists()) {
      const data = authorDoc.data();
      return {
        commentCount: data.insta_comment_ids?.length || 0,
        mediaCount: data.insta_media_ids?.length || 0,
      };
    }
  } catch (error) {
    console.error("Error fetching author stats:", error);
    Sentry.captureException(error);
  }

  return { commentCount: 0, mediaCount: 0 };
}

async function fetchComments() {
  await authenticationStore.waitForInitialization();
  const authenticatedInstaUserId =
    authenticationStore.authenticatedInstaBusinessAccountId;
  if (!authenticatedInstaUserId) {
    console.error("No authenticated Instagram business account ID found");
    return;
  }
  let querySnapshot;

  try {
    querySnapshot = await getDocs(
      query(
        collection(firestoreClient, FIRESTORE_INSTA_COMMENTS),
        where("owner_insta_id", "==", authenticatedInstaUserId),
        where("insta_media_id", "==", props.cluster.insta_media_id),
        where("cluster_label", "==", props.cluster.cluster_label),
        where("reply_to_id", "==", null),
        orderBy("insta_timestamp", "desc"),
      ),
    );
  } catch (error) {
    console.error("Error fetching comments:", error);
    Sentry.captureException(error);
    return;
  }

  const fetchedComments = [];
  for (const doc of querySnapshot.docs) {
    fetchedComments.push({
      id: doc.id,
      ...doc.data(),
      replies: [], // Initialize replies array
    });
  }

  // Fetch replies for each comment
  for (const comment of fetchedComments) {
    const repliesSnapshot = await getDocs(
      query(
        collection(firestoreClient, FIRESTORE_INSTA_COMMENTS),
        where("owner_insta_id", "==", authenticatedInstaUserId),
        where("reply_to_id", "==", comment.insta_id),
      ),
    );
    comment.replies = repliesSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  }

  for (const comment of fetchedComments) {
    if (!authorStats.value[comment.author_insta_id]) {
      authorStats.value[comment.author_insta_id] = await fetchAuthorStats(
        comment.author_insta_id,
      );
    }
    for (const reply of comment.replies) {
      if (!authorStats.value[reply.author_insta_id]) {
        authorStats.value[reply.author_insta_id] = await fetchAuthorStats(
          reply.author_insta_id,
        );
      }
    }
  }

  comments.value = fetchedComments;
  n_comments.value = fetchedComments.length;
  loading.value = false;

  if (fetchedComments.length === 0) {
    Sentry.captureMessage("No non-null comments found for cluster", {
      extra: {
        cluster: props.cluster,
      },
    });
    selfDestruct();
  }
}

const filteredComments = computed(() => {
  return comments.value.filter((comment) => {
    const hasUserReply = comment.replies.some(
      (reply) =>
        reply.author_insta_id ===
        authenticationStore.authenticatedInstaBusinessAccountId,
    );
    return showAllComments.value || !hasUserReply;
  });
});

const toggleAllComments = () => {
  showAllComments.value = !showAllComments.value;
};

async function setFirebaseImageUrl() {
  const firebaseStorageBaseUrl =
    "https://firebasestorage.googleapis.com/v0/b/golden-mastiff.appspot.com/o/thumbnails%2F";
  mediaUrl.value = `${firebaseStorageBaseUrl}${props.cluster.insta_media_id}.jpg?alt=media`;
}

onMounted(async () => {
  await authenticationStore.waitForInitialization();
  await fetchComments();
  await setFirebaseImageUrl();
});

function selfDestruct() {
  emits("self-destruct", props.cluster.id);
}
</script>

<template>
  <div v-if="comments.length > 0" class="comment-cluster card">
    <div class="cluster-info">
      <div v-if="mediaUrl" class="cluster-image">
        <img
          :src="mediaUrl"
          :alt="'Image for cluster: ' + props.cluster.summary"
          :title="props.cluster.summary"
          @error="$event.target.style.display = 'none'"
        />
      </div>
      <div class="cluster-extra">
        <p class="cluster-summary">{{ props.cluster.summary }}</p>
        <p class="cluster-count">
          {{ n_comments }} comment{{ n_comments > 1 ? "s" : "" }}
        </p>
      </div>
    </div>
    <div class="cluster-comments">
      <div v-if="loading" class="comments-loading">
        <div v-for="i in 3" :key="i" class="comment-skeleton"></div>
      </div>
      <div v-else>
        <CommentThread
          v-for="comment in filteredComments"
          :key="comment.id"
          :comment="comment"
          :replies="comment.replies"
          :showImage="false"
          :showReplyInput="
            !comment.replies.some(
              (reply) =>
                reply.author_insta_id ===
                authenticationStore.authenticatedInstaBusinessAccountId,
            )
          "
        >
          <template #author-stats>
            <!-- author stats could be here -->
          </template>
        </CommentThread>
        <button
          v-if="comments.length !== filteredComments.length"
          @click="toggleAllComments"
          class="toggle-comments"
        >
          {{ showAllComments ? "Hide" : "Show" }} all comments
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.comment-cluster {
  min-width: 280px;
  padding: var(--spacing-md);
}

.cluster-info {
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-md);
}

.cluster-image {
  width: 100px;
  height: 100px;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  overflow: hidden;
}

.cluster-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cluster-extra {
  flex: 1;
}

.cluster-summary {
  font-weight: bold;
  margin-bottom: var(--spacing-sm);
}

.cluster-count {
  font-size: 0.8em;
  color: var(--color-text-light, var(--color-text));
}

.cluster-comments {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.comment-skeleton {
  height: 100px;
  background: linear-gradient(
    90deg,
    color-mix(in srgb, var(--color-background), black),
    var(--color-background),
    color-mix(in srgb, var(--color-background), white)
  );
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}

.comment-stats {
  margin-left: 5px;
  font-size: 0.7em;
  vertical-align: super;
}

.toggle-comments {
  margin-top: var(--spacing-md);
  padding: var(--spacing-sm);
  background-color: var(--color-background-alt);
  border: none;
  border-radius: var(--border-radius);
  cursor: pointer;
  color: var(--color-text);
}
</style>
