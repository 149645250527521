<script setup>
import { firestoreClient } from "@/firebaseSetup";
import { useCommentsStore } from "@/stores/comments";
import { doc, getDoc } from "firebase/firestore";
import { RefreshCw, Send, Trash2 } from "lucide-vue-next";
import { computed, onMounted, ref, watch } from "vue";
import CommentItem from "./CommentItem.vue";
import CommentReplies from "./CommentReplies.vue";

const props = defineProps({
  comment: {
    type: Object,
    required: true,
  },
  replies: {
    type: Array,
    default: () => [],
  },
  showReplyInputProp: {
    type: Boolean,
    default: true,
  },
  showImage: {
    type: Boolean,
    default: true,
  },
  imageUrl: {
    type: String,
    default: "",
  },
});

const analysis = ref(null);
const currentReplyIndex = ref(0);
const editingReplyId = ref(null);
const isEditing = ref(false);
const isLoading = ref(false);
const replyText = ref("");
const showReplies = ref(false);
const showReplyInput = ref(props.showReplyInputProp);
const submitErrorMessage = ref(null);

const commentsStore = useCommentsStore();

onMounted(async () => {
  await fetchAnalysis();
});

const fetchAnalysis = async () => {
  isLoading.value = true;
  submitErrorMessage.value = null;
  try {
    const analysisRef = doc(
      firestoreClient,
      "insta_comments",
      props.comment.id,
      "analysis",
      "default",
    );
    const analysisSnap = await getDoc(analysisRef);

    if (analysisSnap.exists()) {
      analysis.value = analysisSnap.data();
      console.log("Analysis:", analysis.value);
    } else {
      console.log("No analysis found for this comment");
      analysis.value = null;
    }
  } catch (err) {
    console.error("Error fetching analysis:", err);
    submitErrorMessage.value = "Failed to load analysis data";
    analysis.value = null;
  } finally {
    isLoading.value = false;
  }
};

const submitReply = async () => {
  const trimmedText = replyText.value.trim();
  if (!trimmedText) return;

  let result;
  if (isEditing.value) {
    result = await commentsStore.updatePendingReply(
      editingReplyId.value,
      trimmedText,
    );
  } else {
    result = await commentsStore.submitReply({
      parentId: props.comment.id,
      text: trimmedText,
    });
  }

  if (!result?.success) {
    console.error("Failed to submit/update reply:", result?.error);
    submitErrorMessage.value = `Failed to ${isEditing.value ? "update" : "submit"} reply`;
    return;
  }

  editingReplyId.value = null;
  isEditing.value = false;
  replyText.value = "";
  showReplies.value = true;
  showReplyInput.value = false;
};

const editReply = (reply) => {
  editingReplyId.value = reply.id;
  isEditing.value = true;
  replyText.value = reply.text;
  showReplyInput.value = true;
};

const cancelEdit = () => {
  editingReplyId.value = null;
  isEditing.value = false;
  replyText.value = "";
  showReplyInput.value = false;
};

const allReplies = computed(() => {
  return commentsStore.getRepliesForComment(props.comment.id);
});

const displayImage = computed(
  () => props.showImage && (props.imageUrl || props.comment.insta_media_id),
);

const getImageUrl = computed(() => {
  if (props.imageUrl) return props.imageUrl;
  return `https://firebasestorage.googleapis.com/v0/b/golden-mastiff.appspot.com/o/thumbnails%2F${props.comment.insta_media_id}.jpg?alt=media`;
});

const suggestedReplies = computed(() => analysis.value?.replies || []);

const currentSuggestedReply = computed(() => {
  if (
    suggestedReplies.value.length > 0 &&
    currentReplyIndex.value < suggestedReplies.value.length
  ) {
    return suggestedReplies.value[currentReplyIndex.value].text || "";
  }
  return "";
});

const cycleReplies = () => {
  if (suggestedReplies.value.length > 0) {
    currentReplyIndex.value =
      (currentReplyIndex.value + 1) % suggestedReplies.value.length;
    replyText.value = currentSuggestedReply.value;
  }
};

const requiresEdit = computed(() => analysis.value?.requires_edit || false);

const replyInputClass = computed(() => ({
  "reply-input": true,
  "requires-edit": requiresEdit.value,
}));

watch(
  analysis,
  (newAnalysis) => {
    if (newAnalysis && newAnalysis.replies && newAnalysis.replies.length > 0) {
      replyText.value = newAnalysis.replies[0].text || "";
    }
  },
  { immediate: true },
);
</script>

<template>
  <div class="comment-thread card">
    <img
      v-if="displayImage"
      :src="getImageUrl"
      class="thread-image"
      alt="Instagram media"
      @error="$event.target.style.display = 'none'"
    />
    <CommentItem
      :comment="comment"
      :isTopLevel="true"
      :showImage="false"
      @edit="editReply"
    >
      <template #author-stats>
        <slot name="author-stats"></slot>
      </template>
    </CommentItem>
    <div v-if="isLoading" class="loading">Loading analysis...</div>
    <div v-else-if="submitErrorMessage" class="error">
      {{ submitErrorMessage }}
    </div>
    <div v-if="showReplyInput || isEditing" :class="replyInputClass">
      <input
        v-model="replyText"
        type="text"
        :placeholder="
          isEditing
            ? 'Edit reply'
            : requiresEdit
              ? 'Edit required'
              : 'Reply to comment'
        "
        @keyup.enter="submitReply"
      />
      <button
        v-if="suggestedReplies.length > 0 && !isEditing"
        class="cycle-btn"
        @click="cycleReplies"
        title="Cycle through suggested replies"
      >
        <RefreshCw :size="18" />
      </button>
      <button class="send-btn" @click="submitReply" title="Send">
        <Send :size="18" />
      </button>
      <button
        v-if="isEditing"
        class="cancel-btn"
        @click="cancelEdit"
        title="Cancel"
      >
        <Trash2 :size="18" />
      </button>
    </div>
    <CommentReplies
      v-if="allReplies.length > 0"
      :replies="allReplies"
      :showImage="false"
      @edit="editReply"
      v-model:showReplies="showReplies"
    >
      <template #author-stats="slotProps">
        <slot name="author-stats" v-bind="slotProps"></slot>
      </template>
    </CommentReplies>
  </div>
</template>

<style scoped>
.comment-thread {
  margin-bottom: var(--spacing-md);
  overflow: hidden;
}

.thread-image {
  width: 128px;
  height: 128px;
  object-fit: cover;
}

.reply-input {
  display: flex;
  padding: var(--spacing-sm);
  border-top: 1px solid var(--border-color);
}

.reply-input.requires-edit {
  background-color: #fff3cd;
}

.reply-input input {
  flex-grow: 1;
  padding: var(--spacing-sm);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  margin-right: var(--spacing-sm);
}

.send-btn,
.cycle-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-sm);
  border-radius: var(--border-radius);
  margin-left: var(--spacing-xs);
  border: none;
  cursor: pointer;
}

.cycle-btn {
  background-color: var(--color-secondary);
  color: white;
}

.send-btn {
  background-color: var(--gaia-green-light);
  color: white;
}

.loading,
.error {
  padding: var(--spacing-sm);
  text-align: center;
}

.error {
  color: var(--color-error);
}

.cancel-btn {
  background-color: var(--gaia-green-light);
  color: white;
}
</style>
