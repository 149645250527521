<script setup>
import { ref } from "vue";
import { RouterLink } from "vue-router";
import { APP_NAME, EMAIL_SUPPORT } from "@/config/app.config";

const currentYear = ref(new Date().getFullYear());
const companyName =
  "Göpfert Artificial Intelligence Applications UG (haftungsbeschränkt)";
</script>

<template>
  <footer class="footer">
    <div class="container footer-content">
      <div class="footer-links">
        <RouterLink to="/privacy-policy">Privacy Policy</RouterLink>
        <RouterLink to="/terms-of-service">Terms of Service</RouterLink>
        <a :href="`mailto:${EMAIL_SUPPORT}`">Contact</a>
      </div>
      <div class="footer-info">
        <p>&copy; {{ currentYear }} {{ companyName }}</p>
        <p>{{ APP_NAME }} - All rights reserved.</p>
      </div>
    </div>
  </footer>
</template>

<style scoped>
.footer {
  background-color: var(--color-background-alt);
  color: var(--color-text);
  padding: var(--spacing-md) 0;
  margin-top: auto;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-md);
}

.footer-links {
  display: flex;
  gap: var(--spacing-md);
}

.footer-links a {
  color: var(--color-text);
  text-decoration: none;
  transition: color 0.3s;
}

.footer-links a:hover {
  color: var(--color-text-emph);
}

.footer-info {
  font-size: var(--font-size-small);
  opacity: 0.8;
  text-align: center;
}

@media (min-width: 768px) {
  .footer-content {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .footer-info {
    text-align: right;
  }
}
</style>
