<script setup>
import ClusterPerMedia from "@/components/ClusterPerMedia.vue";
import { firestoreClient } from "@/firebaseSetup";
import { useAuthenticationStore } from "@/stores/authentication";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { onMounted, onUnmounted, ref, watch } from "vue";
import { FIRESTORE_CLUSTERS } from "@/constants";

const loading = ref(true);
const clusters = ref([]);
const authenticationStore = useAuthenticationStore();

async function subscribeToClusters(firestoreClient) {
  const authenticatedInstaUserId =
    authenticationStore.authenticatedInstaBusinessAccountId;

  if (!authenticatedInstaUserId) {
    console.error("No authenticated Instagram business account ID found");
    return () => {};
  }

  const instaClusterCollection = collection(
    firestoreClient,
    FIRESTORE_CLUSTERS,
  );
  const authenticatedUserClustersQuery = query(
    instaClusterCollection,
    where("insta_user_id", "==", authenticatedInstaUserId),
    orderBy("youngest_insta_timestamp", "desc"),
  );

  const unsubscribe = onSnapshot(
    authenticatedUserClustersQuery,
    (collectionSnapshot) => {
      const fetchedClusters = [];
      for (const doc of collectionSnapshot.docs) {
        fetchedClusters.push({
          id: doc.id,
          ...doc.data(),
        });
      }
      clusters.value = fetchedClusters;
      loading.value = false;
    },
  );
  return unsubscribe;
}

let unsub = () => {};

watch(
  () => authenticationStore.authenticatedInstaBusinessAccountId,
  async (newId) => {
    if (newId) {
      unsub();
      unsub = await subscribeToClusters(firestoreClient);
    } else {
      unsub();
      clusters.value = [];
      loading.value = false;
    }
  },
);

onMounted(async () => {
  await authenticationStore.waitForInitialization();
  if (authenticationStore.authenticatedInstaBusinessAccountId) {
    unsub = await subscribeToClusters(firestoreClient);
  } else {
    loading.value = false;
  }
});

onUnmounted(() => {
  unsub();
});

const handleSelfDestruct = (clusterId) => {
  clusters.value = clusters.value.filter((cluster) => cluster.id !== clusterId);
};
</script>

<template>
  <div v-if="authenticationStore.isInitialized" class="user-clusters">
    <div v-if="authenticationStore.isAuthenticated" class="container">
      <ul v-if="clusters.length > 0" class="cluster-list">
        <li v-for="cluster in clusters" :key="cluster.id">
          <ClusterPerMedia
            :cluster="cluster"
            @self-destruct="handleSelfDestruct"
          />
        </li>
      </ul>
      <div v-else-if="loading" class="loading-clusters">
        <div v-for="i in 6" :key="i" class="cluster-skeleton"></div>
      </div>
      <p v-else class="no-clusters">No clusters found</p>
    </div>
  </div>
</template>

<style scoped>
.user-clusters {
  background-color: var(--background-light);
  transition: background-color 0.3s;
}

.cluster-list {
  list-style: none;
  padding: 0;
  display: grid;
  gap: var(--spacing-md);
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
}

.loading-clusters {
  display: grid;
  gap: var(--spacing-md);
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
}

.cluster-skeleton {
  height: 300px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
  border-radius: var(--border-radius);
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}

.no-clusters {
  text-align: center;
  font-size: 1.2em;
  color: var(--secondary-color);
}

@media (max-width: 768px) {
  .cluster-list,
  .loading-clusters {
    grid-template-columns: 1fr;
  }
}
</style>
