import "@fontsource-variable/lexend";
import { createPinia } from "pinia";
import { createApp } from "vue";
import App from "./App.vue";
import "./assets/global.css";
import router from "./services/routing";

import * as Sentry from "@sentry/vue";

const pinia = createPinia();

const app = createApp(App);

Sentry.init({
  app,
  dsn: "https://431c696f41737a383b5455da0a512fcb@o4507606289285120.ingest.de.sentry.io/4507606292496464",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.browserTracingIntegration({ router }),
  ],
  trackComponents: true,
  hooks: ["mount", "update", "unmount"],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/golden-mastiff\.firebaseapp\.com/,
  ],
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
});

app.use(pinia);
app.use(router);
app.mount("#app");
