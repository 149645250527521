<script setup>
import CommentItem from "./CommentItem.vue";

const props = defineProps({
  replies: {
    type: Array,
    required: true,
  },
  showReplies: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:showReplies", "edit"]);

const toggleReplies = () => {
  emit("update:showReplies", !props.showReplies);
};

const handleEdit = (reply) => {
  emit("edit", reply);
};
</script>

<template>
  <div class="comment-replies">
    <a href="#" @click.prevent="toggleReplies" class="toggle-replies">
      {{ props.showReplies ? "Hide" : "Show" }} {{ props.replies.length }}
      {{ props.replies.length === 1 ? "reply" : "replies" }}
    </a>
    <div v-if="props.showReplies" class="replies-list">
      <CommentItem
        v-for="reply in props.replies"
        :key="reply.id"
        :comment="reply"
        :isTopLevel="false"
        @edit="handleEdit"
        :id="`reply-${reply.id}`"
      >
        <template #author-stats>
          <slot name="author-stats" :comment="reply"></slot>
        </template>
      </CommentItem>
    </div>
  </div>
</template>

<style scoped>
.comment-replies {
  padding-left: var(--spacing-md);
  border-left: 1px solid var(--border-color);
  margin-top: var(--spacing-sm);
}

.toggle-replies {
  font-size: 0.9em;
  /* This might need justification or a new CSS variable */
  color: var(--color-text-emph);
}

.replies-list {
  margin-top: var(--spacing-sm);
}
</style>
