import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDu53E07DqCak5H6gllboITixDPVYNfwwU",
  authDomain: "golden-mastiff.firebaseapp.com",
  projectId: "golden-mastiff",
  storageBucket: "golden-mastiff.appspot.com",
  messagingSenderId: "547831982449",
  appId: "1:547831982449:web:28845dfe47cf8479b5eed0",
  measurementId: "G-ETBX83HZP5",
};

const firebaseApp = initializeApp(firebaseConfig);
const firebaseAuth = getAuth(firebaseApp);
const firebaseAnalytics = getAnalytics(firebaseApp);
const firestoreClient = getFirestore(firebaseApp);

export { firebaseAnalytics, firebaseApp, firebaseAuth, firestoreClient };
