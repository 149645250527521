export const FIRESTORE_BUSINESS_ACCOUNTS = "insta_business_accounts";
export const FIRESTORE_CLUSTERING_JOBS = "clustering_jobs";
export const FIRESTORE_CLUSTERS = "clusters";
export const FIRESTORE_EMBEDDINGS = "embeddings";
export const FIRESTORE_FIRE_USERS = "fire_users";
export const FIRESTORE_INSTA_AUTHORS = "insta_authors";
export const FIRESTORE_INSTA_COMMENTS = "insta_comments";
export const FIRESTORE_INSTA_MEDIA = "insta_media";
export const FIRESTORE_INSTA_USERS = "insta_users";
export const FIRESTORE_INSTAGRAM_ACCOUNTS = "instagram_accounts";
export const FIRESTORE_PENDING_REPLIES = "pending_replies";
