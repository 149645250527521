import { firebaseAuth, firestoreClient } from "@/firebaseSetup";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

import { FIRESTORE_INSTA_USERS, FIRESTORE_FIRE_USERS } from "@/constants";

export const useAuthenticationStore = defineStore("authentication", () => {
  const user = ref(null);
  const isAuthenticated = computed(() => user.value !== null);
  const initialized = ref(false);
  const authenticatedInstaBusinessAccountId = ref(null);
  const instaUsername = ref(null);
  const instaProfilePictureUrl = ref(null);

  let authInitializationPromise = null;

  const initializeAuthListener = () => {
    if (!authInitializationPromise) {
      authInitializationPromise = new Promise((resolve) => {
        onAuthStateChanged(firebaseAuth, async (authUser) => {
          user.value = authUser;
          if (authUser) {
            try {
              await fetchAuthenticatedInstaBusinessAccountId();
            } catch (error) {
              console.error(
                "Error fetching authenticated Insta business account ID:",
                error,
              );
              authenticatedInstaBusinessAccountId.value = null;
              instaUsername.value = null;
              instaProfilePictureUrl.value = null;
            }
          } else {
            authenticatedInstaBusinessAccountId.value = null;
            instaUsername.value = null;
            instaProfilePictureUrl.value = null;
          }
          initialized.value = true;
          resolve();
        });
      });
    }
    return authInitializationPromise;
  };

  const waitForInitialization = () => {
    return authInitializationPromise || initializeAuthListener();
  };

  const fetchInstaUIDFromFireUser = async (fireUID) => {
    const fireUserDoc = doc(firestoreClient, FIRESTORE_FIRE_USERS, fireUID);

    let fireUserSnap;
    try {
      fireUserSnap = await getDoc(fireUserDoc);
    } catch (error) {
      console.error(`Cannot access ${FIRESTORE_FIRE_USERS}/${fireUID}`, error);
      return null;
    }

    if (!fireUserSnap.exists()) {
      return null;
    }

    const fireUserData = fireUserSnap.data();

    let instaUserId;
    try {
      instaUserId = fireUserData.insta_user_ids[0];
    } catch (error) {
      console.error(
        "Error fetching Insta user ID from Fire user document:",
        error,
        FIRESTORE_FIRE_USERS,
        fireUID,
        fireUserData,
      );
      return null;
    }

    return instaUserId;
  };

  const fetchInstaUserDataFomInstaUser = async (instaUID) => {
    const instaUserDoc = doc(firestoreClient, FIRESTORE_INSTA_USERS, instaUID);

    let instaUserSnap;
    try {
      instaUserSnap = await getDoc(instaUserDoc);
    } catch (error) {
      console.warn(
        `Error fetching ${FIRESTORE_INSTA_USERS}/${instaUID}`,
        error,
      );
      authenticatedInstaBusinessAccountId.value = null;
      instaUsername.value = null;
      instaProfilePictureUrl.value = null;
      return null;
    }

    if (!instaUserSnap.exists()) {
      console.warn(
        `Insta user document ${FIRESTORE_INSTA_USERS}/${instaUID} does not exist`,
      );

      authenticatedInstaBusinessAccountId.value = null;
      instaUsername.value = null;
      instaProfilePictureUrl.value = null;
      return null;
    }

    const instaUserData = instaUserSnap.data();

    authenticatedInstaBusinessAccountId.value = instaUserData.insta_user_id;
    instaUsername.value = instaUserData.insta_username;
    instaProfilePictureUrl.value = instaUserData.insta_profile_picture_url;

    return {
      id: instaUserData.insta_user_id,
      username: instaUserData.insta_username,
      profilePictureUrl: instaUserData.insta_profile_picture_url,
    };
  };

  const fetchAuthenticatedInstaBusinessAccountId = async () => {
    if (!user.value) {
      authenticatedInstaBusinessAccountId.value = null;
      instaUsername.value = null;
      instaProfilePictureUrl.value = null;
      return;
    }

    const fireUID = user.value.uid;
    let retries = 3;
    let delay = 1000; // Start with a 1 second delay

    while (retries > 0) {
      const instaUIDFromFire = await fetchInstaUIDFromFireUser(fireUID);
      if (instaUIDFromFire) {
        console.debug(
          "Found Insta user ID from Fire user document:",
          instaUIDFromFire,
        );

        const instaUidInFire = `insta:${instaUIDFromFire}`;

        const instaUserData =
          await fetchInstaUserDataFomInstaUser(instaUidInFire);
        if (instaUserData) {
          return;
        }
      }

      const instaUserData = await fetchInstaUserDataFomInstaUser(fireUID);
      if (instaUserData) {
        return;
      }

      // If we haven't found the ID, wait before retrying
      console.debug(
        `Retrying to fetch authenticated Insta business account ID in ${delay}ms...
        Retries left: ${retries}`,
      );
      await new Promise((resolve) => setTimeout(resolve, delay));
      retries--;
      delay *= 2; // Double the delay for each retry (exponential backoff)
    }

    authenticatedInstaBusinessAccountId.value = null;
    instaUsername.value = null;
    instaProfilePictureUrl.value = null;
  };

  return {
    user,
    isAuthenticated,
    initialized,
    initializeAuthListener,
    authenticatedInstaBusinessAccountId,
    instaUsername,
    instaProfilePictureUrl,
    fetchAuthenticatedInstaBusinessAccountId,
    waitForInitialization,
    isInitialized: computed(() => initialized.value),
  };
});
