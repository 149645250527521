<script setup>
import CommonHeader from "@/components/CommonHeader.vue";
import CommonFooter from "@/components/CommonFooter.vue";
import { firebaseAuth } from "@/firebaseSetup";
import router from "@/services/routing";
import * as Sentry from "@sentry/vue";
import { signOut } from "firebase/auth";

const handleSignOut = async () => {
  try {
    await signOut(firebaseAuth);
    Sentry.captureMessage("User signed out");
    router.push({ name: "login" });
  } catch (error) {
    Sentry.captureException(error);
  }
};
</script>

<template>
  <div class="authenticated-layout">
    <CommonHeader :showMenu="true" @signOut="handleSignOut" />
    <main class="main-content">
      <div class="container">
        <router-view />
      </div>
    </main>
    <CommonFooter />
  </div>
</template>

<style scoped>
.authenticated-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
  width: 100%;
}
</style>
