<script setup>
import { FIRESTORE_INSTA_AUTHORS, FIRESTORE_INSTA_COMMENTS } from "@/constants";
import { firestoreClient } from "@/firebaseSetup";
import { useAuthenticationStore } from "@/stores/authentication";
import * as Sentry from "@sentry/vue";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import CommentThread from "./CommentThread.vue";

const route = useRoute();
const authorId = ref(route.params.id);
const authenticationStore = useAuthenticationStore();

const loading = ref(true);
const authorName = ref("");
const conversations = ref([]);

const fetchAuthorConversations = async () => {
  if (!authorId.value) {
    console.error("No author ID provided");
    loading.value = false;
    return;
  }

  try {
    const authenticatedInstaUserId =
      authenticationStore.authenticatedInstaBusinessAccountId;
    if (!authenticatedInstaUserId) {
      console.error("No authenticated Instagram business account ID found");
      loading.value = false;
      return;
    }

    const joinedAuthorId = `${authenticatedInstaUserId}:${authorId.value}`;
    const authorDocRef = doc(
      firestoreClient,
      FIRESTORE_INSTA_AUTHORS,
      joinedAuthorId,
    );
    const authorDocSnapshot = await getDoc(authorDocRef);

    if (!authorDocSnapshot.exists()) {
      console.error("Author document not found");
      loading.value = false;
      return;
    }

    const authorData = authorDocSnapshot.data();
    authorName.value = authorData.author_insta_username;

    const commentsQuery = query(
      collection(firestoreClient, FIRESTORE_INSTA_COMMENTS),
      where("owner_insta_id", "==", authenticatedInstaUserId),
      where("author_insta_id", "==", authorId.value),
    );

    const querySnapshot = await getDocs(commentsQuery);
    const commentsMap = new Map();
    const replyIdsToFetch = new Set();

    querySnapshot.docs.forEach((doc) => {
      const comment = { id: doc.id, ...doc.data(), replies: [] };
      commentsMap.set(comment.insta_id, comment);
      if (comment.reply_ids) {
        comment.reply_ids.forEach((replyId) => {
          if (!commentsMap.has(replyId)) {
            replyIdsToFetch.add(replyId);
          }
        });
      }
    });

    // Fetch replies
    if (replyIdsToFetch.size > 0) {
      const replyChunks = Array.from(replyIdsToFetch).reduce(
        (resultArray, item, index) => {
          const chunkIndex = Math.floor(index / 10);
          if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = []; // start a new chunk
          }
          resultArray[chunkIndex].push(item);
          return resultArray;
        },
        [],
      );

      for (const chunk of replyChunks) {
        const repliesQuery = query(
          collection(firestoreClient, FIRESTORE_INSTA_COMMENTS),
          where("owner_insta_id", "==", authenticatedInstaUserId),
          where("insta_id", "in", chunk),
        );
        const repliesSnapshot = await getDocs(repliesQuery);
        repliesSnapshot.docs.forEach((doc) => {
          const reply = { id: doc.id, ...doc.data(), replies: [] };
          commentsMap.set(reply.insta_id, reply);
        });
      }
    }

    // Organize comments and replies
    const topLevelComments = [];
    commentsMap.forEach((comment) => {
      if (!comment.reply_to_id) {
        topLevelComments.push(comment);
      } else {
        const parentComment = commentsMap.get(comment.reply_to_id);
        if (parentComment) {
          parentComment.replies.push(comment);
        } else {
          // If parent comment is not found, treat this as a top-level comment
          topLevelComments.push(comment);
        }
      }
    });

    conversations.value = topLevelComments.sort(
      (a, b) => new Date(b.insta_timestamp) - new Date(a.insta_timestamp),
    );
    conversations.value.forEach((conversation) => {
      // Sort replies and filter out any replies that weren't found
      conversation.replies = conversation.replies.filter((reply) =>
        commentsMap.has(reply.insta_id),
      );
      conversation.replies.sort(
        (a, b) => new Date(a.insta_timestamp) - new Date(b.insta_timestamp),
      );
    });

    loading.value = false;
  } catch (error) {
    console.error("Error fetching author conversations:", error);
    Sentry.captureException(error);
    loading.value = false;
  }
};

onMounted(fetchAuthorConversations);

const handleReply = async (replyData) => {
  // Implement reply logic here
  console.log("Reply:", replyData);
  // You might want to add the new reply to the conversations array
  // and/or send it to the server
};
</script>

<template>
  <div class="author-conversations">
    <h2 v-if="authorName">Conversations with @{{ authorName }}</h2>
    <div v-if="loading" class="loading">
      <p>Loading conversations...</p>
    </div>
    <div v-else-if="conversations.length === 0" class="no-conversations">
      <p>No conversations found for this author.</p>
    </div>
    <div v-else class="conversations-list">
      <CommentThread
        v-for="conversation in conversations"
        :key="conversation.id"
        :comment="conversation"
        :replies="conversation.replies"
        @reply="handleReply"
      />
    </div>
  </div>
</template>

<style scoped>
.author-conversations {
  max-width: 800px;
  margin: 0 auto;
  padding: var(--spacing-md);
  font-family: Arial, sans-serif;
}

.loading,
.no-conversations {
  text-align: center;
  color: var(--color-text);
}

.conversations-list {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}
</style>
