<script setup>
import { ref } from "vue";
import LegalDocument from "@/components/LegalDocument.vue";
import rawTermsOfService from "@/assets/terms-of-service.html?raw";

const termsOfServiceContent = ref(rawTermsOfService);
</script>

<template>
  <LegalDocument :content="termsOfServiceContent" />
</template>
