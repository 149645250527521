import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { firestoreClient } from "@/firebaseSetup";
import {
  collection,
  addDoc,
  doc,
  updateDoc,
  getDoc,
  serverTimestamp,
  onSnapshot,
} from "firebase/firestore";
import * as Sentry from "@sentry/vue";
import { FIRESTORE_PENDING_REPLIES } from "@/constants";
import { useAuthenticationStore } from "@/stores/authentication";

export const useCommentsStore = defineStore("comments", () => {
  const comments = ref({});
  const pendingReplies = ref({});
  const authenticationStore = useAuthenticationStore();

  const addComment = (comment) => {
    comments.value[comment.id] = comment;
  };

  const subscribeToPendingReply = (replyId) => {
    const unsubscribe = onSnapshot(
      doc(firestoreClient, FIRESTORE_PENDING_REPLIES, replyId),
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          const replyData = docSnapshot.data();
          // Update the pendingReplies state
          for (const parentId in pendingReplies.value) {
            const replyIndex = pendingReplies.value[parentId].findIndex(
              (reply) => reply.id === replyId,
            );
            if (replyIndex !== -1) {
              pendingReplies.value[parentId][replyIndex] = {
                id: replyId,
                ...replyData,
              };
              break;
            }
          }
        } else {
          // Reply might have been processed and removed
          for (const parentId in pendingReplies.value) {
            pendingReplies.value[parentId] = pendingReplies.value[
              parentId
            ].filter((reply) => reply.id !== replyId);
          }
        }
      },
      (error) => {
        console.error("Error listening to pending reply:", error);
        Sentry.captureException(error);
      },
    );
    return unsubscribe;
  };

  const addPendingReply = (parentId, reply) => {
    if (!pendingReplies.value[parentId]) {
      pendingReplies.value[parentId] = [];
    }
    pendingReplies.value[parentId].push(reply);
  };

  const submitReply = async ({ parentId, text }) => {
    const ownerInstaId =
      authenticationStore.authenticatedInstaBusinessAccountId;
    try {
      const replyData = {
        parent_comment_id: parentId,
        text: text,
        status: "pending",
        owner_insta_id: ownerInstaId,
        timestamp_created: serverTimestamp(),
        author_insta_username: null,
        timestamp_sent: null,
        error_message: null,
        error_timestamp: null,
      };

      const docRef = await addDoc(
        collection(firestoreClient, FIRESTORE_PENDING_REPLIES),
        replyData,
      );

      const newReply = { id: docRef.id, ...replyData };
      addPendingReply(parentId, newReply);

      subscribeToPendingReply(docRef.id);

      return { success: true, reply: newReply };
    } catch (error) {
      console.error("Error adding reply:", error);
      Sentry.captureException(error);
      return { success: false, error };
    }
  };

  const updatePendingReply = async (replyId, newText) => {
    try {
      await updateDoc(
        doc(firestoreClient, FIRESTORE_PENDING_REPLIES, replyId),
        {
          text: newText,
        },
      );

      // Update the local state
      for (const parentId in pendingReplies.value) {
        const replyIndex = pendingReplies.value[parentId].findIndex(
          (reply) => reply.id === replyId,
        );
        if (replyIndex !== -1) {
          pendingReplies.value[parentId][replyIndex].text = newText;
          return {
            success: true,
            reply: pendingReplies.value[parentId][replyIndex],
          };
        }
      }

      return { success: false, error: "Reply not found" };
    } catch (error) {
      console.error("Error updating pending reply:", error);
      Sentry.captureException(error);
      return { success: false, error };
    }
  };

  const getReplyStatus = async (replyId) => {
    try {
      const replyDoc = await getDoc(
        doc(firestoreClient, FIRESTORE_PENDING_REPLIES, replyId),
      );
      if (replyDoc.exists()) {
        return replyDoc.data().status;
      }
      return null;
    } catch (error) {
      console.error("Error getting reply status:", error);
      Sentry.captureException(error);
      return null;
    }
  };

  const getRepliesForComment = computed(() => {
    return (commentId) => {
      const comment = comments.value[commentId];
      const confirmedReplies = comment?.replies || [];
      const pendingRepliesForComment = pendingReplies.value[commentId] || [];
      return [...confirmedReplies, ...pendingRepliesForComment];
    };
  });

  const removePendingReply = (parentId, replyId) => {
    if (pendingReplies.value[parentId]) {
      pendingReplies.value[parentId] = pendingReplies.value[parentId].filter(
        (reply) => reply.id !== replyId,
      );
    }
  };

  const updateReplyStatus = async (replyId, newStatus) => {
    try {
      await updateDoc(
        doc(firestoreClient, FIRESTORE_PENDING_REPLIES, replyId),
        {
          status: newStatus,
        },
      );

      // Update local state
      for (const parentId in pendingReplies.value) {
        const replyIndex = pendingReplies.value[parentId].findIndex(
          (reply) => reply.id === replyId,
        );
        if (replyIndex !== -1) {
          pendingReplies.value[parentId][replyIndex].status = newStatus;
          if (newStatus === "processed") {
            removePendingReply(parentId, replyId);
          }
          return { success: true };
        }
      }

      return { success: false, error: "Reply not found" };
    } catch (error) {
      console.error("Error updating reply status:", error);
      Sentry.captureException(error);
      return { success: false, error };
    }
  };

  return {
    addComment,
    addPendingReply,
    comments,
    getRepliesForComment,
    getReplyStatus,
    pendingReplies,
    removePendingReply,
    submitReply,
    subscribeToPendingReply,
    updatePendingReply,
    updateReplyStatus,
  };
});
