<script setup>
import { APP_NAME } from "@/config/app.config";
import { LogOut, Menu, Settings, X } from "lucide-vue-next";
import { ref, computed } from "vue";
import { useAuthenticationStore } from "@/stores/authentication";

const props = defineProps({
  showMenu: {
    type: Boolean,
    default: false,
  },
});

const authStore = useAuthenticationStore();
const isMenuOpen = ref(false);

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value;
};

const emit = defineEmits(["signOut"]);

const signOut = () => {
  emit("signOut");
};

const instaUsername = computed(() => authStore.instaUsername);
const instaProfilePictureUrl = computed(() => authStore.instaProfilePictureUrl);
</script>

<template>
  <header class="header">
    <div class="container header-content">
      <router-link to="/" class="logo-link">
        <div class="title-container">
          <img src="@/assets/logo.svg" alt="Logo" class="logo" />
          <h1>{{ APP_NAME }}</h1>
        </div>
      </router-link>
      <div v-if="props.showMenu" class="header-actions">
        <div v-if="instaUsername" class="user-info">
          <img
            v-if="instaProfilePictureUrl"
            :src="instaProfilePictureUrl"
            :alt="instaUsername"
            class="profile-picture"
          />
          <span class="username">{{ instaUsername }}</span>
        </div>
        <button
          @click="toggleMenu"
          class="menu-button"
          aria-label="Toggle menu"
        >
          <Menu v-if="!isMenuOpen" />
          <X v-else />
        </button>
        <nav :class="['menu', { 'menu-open': isMenuOpen }]">
          <router-link to="/settings" class="menu-item">
            <Settings class="icon" />
            Settings
          </router-link>
          <button @click="signOut" class="menu-item">
            <LogOut class="icon" />
            Sign Out
          </button>
        </nav>
      </div>
    </div>
  </header>
</template>

<style scoped>
.header {
  width: 100%;
  background-color: var(--color-background);
  color: var(--color-text);
  padding: var(--spacing-sm) 0;
  position: sticky;
  top: 0;
  z-index: 1000;
  transition:
    background-color 0.3s,
    color 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding: 0 var(--spacing-md);
}

.logo-link {
  text-decoration: none;
  color: inherit;
}

.title-container {
  display: flex;
  align-items: center;
}

.logo {
  height: 30px;
  margin-right: 10px;
}

h1 {
  font-variant: small-caps;
  margin: 0;
}

.header-actions {
  position: relative;
}

.menu-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  color: var(--color-text);
}

.menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: var(--color-background);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  padding: var(--spacing-sm);
  display: none;
  flex-direction: column;
  gap: var(--spacing-sm);
  min-width: 200px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.menu-open {
  display: flex;
}

.menu-item {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  padding: var(--spacing-sm);
  color: var(--color-text);
  text-decoration: none;
  transition: background-color 0.3s;
  border-radius: var(--border-radius);
}

.menu-item:hover {
  background-color: var(--color-background-alt);
}

button.menu-item {
  width: 100%;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  font: inherit;
}

.main-content {
  flex: 1;
  width: 100%;
}

.icon {
  width: 1.5em;
  height: 1.5em;
  margin-right: 0.5em;
}

.menu-button .icon {
  width: 1.75em;
  height: 1.75em;
}

@media (min-width: 768px) {
  .menu-button {
    display: none;
  }

  .menu {
    position: static;
    display: flex;
    flex-direction: row;
    background: none;
    border: none;
    box-shadow: none;
    padding: 0;
  }

  .menu-item {
    padding: 0.5em 0.75em;
    gap: 0.3em;
  }

  .icon {
    width: 1.25em;
    height: 1.25em;
    margin-right: 0.3em;
  }
}

.user-info {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.profile-picture {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.username {
  font-weight: bold;
}

@media (max-width: 767px) {
  .user-info {
    display: none;
  }
}
</style>
