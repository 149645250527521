<script setup>
import { ref, computed, onMounted, onUnmounted } from "vue";
import { useCommentsStore } from "@/stores/comments";
import { Pencil } from "lucide-vue-next";

const props = defineProps({
  replyId: {
    type: String,
    required: true,
  },
  parentId: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["edit"]);

const commentsStore = useCommentsStore();
const currentTime = ref(Date.now());

const pendingReply = computed(() => {
  const replies = commentsStore.pendingReplies[props.parentId] || [];
  return replies.find((reply) => reply.id === props.replyId) || null;
});

const createdAtTime = computed(() => {
  const timestamp = pendingReply.value?.timestamp_created;
  if (timestamp?.toDate) return timestamp.toDate().getTime();
  if (timestamp?.seconds) return timestamp.seconds * 1000;
  return Date.now(); // Fallback
});

const elapsedSeconds = computed(() => {
  return Math.floor((currentTime.value - createdAtTime.value) / 1000);
});

const timeLeft = computed(() => {
  if (!pendingReply.value) return 0;
  return Math.max(120 - elapsedSeconds.value, 0);
});

const canEdit = computed(() => {
  return timeLeft.value > 0 && pendingReply.value?.status === "pending";
});

const displayText = computed(() => {
  if (!pendingReply.value) return "";
  if (timeLeft.value > 0) {
    return `Posting reply in ${formatTime(timeLeft.value)}`;
  } else {
    return `Reply is about to be posted`;
  }
});

const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
};

const handleEdit = () => {
  emit("edit");
};

let timer;
let unsubscribe;

onMounted(() => {
  unsubscribe = commentsStore.subscribeToPendingReply(props.replyId);
  timer = setInterval(() => {
    currentTime.value = Date.now();
  }, 1000);
});

onUnmounted(() => {
  if (unsubscribe) unsubscribe();
  if (timer) clearInterval(timer);
});
</script>
<template>
  <div v-if="pendingReply" class="pending-reply-timer">
    <span>{{ displayText }}</span>
    <button v-if="canEdit" @click="handleEdit" class="edit-button" title="Edit">
      <Pencil size="16" />
    </button>
  </div>
</template>

<style scoped>
.pending-reply-timer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: var(--spacing-sm);
  font-size: 0.9em;
  color: var(--color-text-light);
}

.edit-button {
  background-color: var(--gaia-green-light);
  color: white;
}

.edit-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
