<script setup>
import { useAuthenticationStore } from "@/stores/authentication";
import { ref, computed } from "vue";
import { Info } from "lucide-vue-next";
import PendingReplyTimer from "./PendingReplyTimer.vue";

const props = defineProps({
  comment: {
    type: Object,
    required: true,
  },
  isTopLevel: {
    type: Boolean,
    default: false,
  },
  showReplyInput: {
    type: Boolean,
    default: true,
  },
  showImage: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(["edit"]);

const authStore = useAuthenticationStore();
const showTooltip = ref(false);

const isOwner = computed(() => {
  return (
    props.comment.owner_insta_id ===
    authStore.authenticatedInstaBusinessAccountId
  );
});

const isAnonymous = computed(() => {
  return props.comment.author_insta_username === "anonymous";
});

const isPending = computed(() => {
  return props.comment.status === "pending";
});

const formatTimestamp = (timestampString) => {
  const timestamp = new Date(timestampString);
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  return timestamp.toLocaleDateString(undefined, options);
};

const toggleTooltip = () => {
  showTooltip.value = !showTooltip.value;
};

const handleEdit = () => {
  emit("edit", props.comment);
};
</script>

<template>
  <div
    class="comment"
    :class="{
      'top-level': isTopLevel,
      'owner-comment': isOwner,
      'pending-comment': isPending,
    }"
  >
    <div class="comment-header">
      <p class="comment-author">
        <router-link
          :to="{
            name: 'author',
            params: { id: comment.author_insta_id || comment.owner_insta_id },
          }"
        >
          {{ comment.author_insta_username }}
          <span v-if="isAnonymous" class="info-icon-wrapper">
            <Info :size="16" class="info-icon" @click.prevent="toggleTooltip" />
            <span class="tooltip" :class="{ 'tooltip-visible': showTooltip }">
              Username redacted
            </span>
          </span>
          <slot name="author-stats"></slot>
        </router-link>
      </p>
      <p v-if="!isPending" class="comment-timestamp">
        {{ formatTimestamp(comment.insta_timestamp) || "" }}
      </p>
    </div>
    <p class="comment-text">{{ comment.insta_text || comment.text }}</p>
    <PendingReplyTimer
      v-if="isPending"
      :replyId="comment.id"
      :parentId="comment.parent_comment_id"
      @edit="handleEdit"
    />
  </div>
</template>

<style scoped>
.comment {
  padding: var(--spacing-sm);
  position: relative;
  margin-top: var(--spacing-md); /* Add space above for tooltip */
}

.top-level {
  border-bottom: 1px solid var(--border-color);
}

.owner-comment {
  background-color: var(--color-background-alt);
}

.comment-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-sm);
}

.comment-author {
  font-weight: var(--font-weight-bold);
  color: var(--color-text-emph);
  display: flex;
  align-items: center;
}

.comment-timestamp {
  font-size: var(--font-size-small);
  color: var(--color-text);
}

.comment-text {
  margin-bottom: var(--spacing-sm);
  line-height: 1.5;
}

.info-icon-wrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
  margin-left: var(--spacing-sm);
}

.info-icon {
  cursor: pointer;
  color: var(--color-text);
}

.tooltip {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: calc(var(--spacing-md));
  left: 50%;
  background-color: var(--color-background);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  padding: var(--spacing-sm);
  font-size: var(--font-size-small);
  white-space: nowrap;
  z-index: 1000;
  box-shadow: 0 2px 4px var(--color-shadow);
  transition:
    visibility 0s,
    opacity 0.2s;
}

.tooltip-visible {
  visibility: visible;
  opacity: 1;
}

/* Hover styles for desktop */
@media (hover: hover) and (pointer: fine) {
  .info-icon-wrapper:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }
}

/* Ensure tooltip is not cut off */
.comment-author {
  overflow: visible;
}

.pending-comment {
  background-color: var(--color-background-light);
  border: 1px dashed var(--border-color);
}
</style>
