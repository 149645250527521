import AuthenticatedLayout from "@/layouts/AuthenticatedLayout.vue";
import UnauthenticatedLayout from "@/layouts/UnauthenticatedLayout.vue";
import { useAuthenticationStore } from "@/stores/authentication";
import AuthorView from "@/views/AuthorView.vue";
import LoginView from "@/views/LoginView.vue";
import ClustersPerMediaView from "@/views/ClustersPerMediaView.vue";
import SettingsView from "@/views/SettingsView.vue";
import PrivacyPolicyView from "@/views/PrivacyPolicyView.vue";
import TermsOfServiceView from "@/views/TermsOfServiceView.vue";
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    component: AuthenticatedLayout,
    meta: { requiresAuth: true },
    children: [
      { path: "", name: "home", component: ClustersPerMediaView },
      { path: "authors/:id", name: "author", component: AuthorView },
      { path: "settings", name: "settings", component: SettingsView },
    ],
  },
  {
    path: "/login",
    component: UnauthenticatedLayout,
    children: [{ path: "", name: "login", component: LoginView }],
  },
  {
    path: "/privacy-policy",
    component: UnauthenticatedLayout,
    children: [
      { path: "", name: "privacyPolicy", component: PrivacyPolicyView },
    ],
  },
  {
    path: "/terms-of-service",
    component: UnauthenticatedLayout,
    children: [
      { path: "", name: "termsOfService", component: TermsOfServiceView },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to) => {
  const authenticationStore = useAuthenticationStore();

  if (!authenticationStore.initialized) {
    await authenticationStore.initializeAuthListener();
  }

  if (to.meta.requiresAuth && !authenticationStore.isAuthenticated) {
    return { name: "login" };
  }

  return true;
});

export default router;
