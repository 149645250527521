<script setup>
import { ref, onMounted } from "vue";
import { APP_NAME } from "@/config/app.config";
import { firebaseAuth, firestoreClient } from "@/firebaseSetup";
import router from "@/services/routing";
import * as Sentry from "@sentry/vue";
import {
  FacebookAuthProvider,
  signInWithPopup,
  signInWithCustomToken,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";

const loading = ref(false);
const errorMessage = ref("");
const email = ref("");
const password = ref("");
const loginSuccess = ref(false);

// Feature flag to enable/disable email login
const ENABLE_EMAIL_LOGIN = true;

// Delay time in milliseconds before forwarding
const FORWARD_DELAY = 2000;

const facebookProvider = new FacebookAuthProvider().setCustomParameters({
  config_id: "505114468707838",
});

const updateUserInFirestore = async (user, token = null) => {
  try {
    const userData = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      providerId: user.providerId,
    };
    if (token) {
      userData.token = token;
    }
    await setDoc(doc(firestoreClient, "fire_users", user.uid), userData, {
      merge: true,
    });
    Sentry.captureMessage("User data updated in Firestore");
  } catch (firestoreError) {
    console.error("Error writing document:", firestoreError);
    Sentry.captureException(firestoreError);
  }
};

const signInWithFacebook = async () => {
  loading.value = true;
  errorMessage.value = "";
  try {
    const result = await signInWithPopup(firebaseAuth, facebookProvider);
    const credential = FacebookAuthProvider.credentialFromResult(result);
    const token = credential.accessToken;
    await updateUserInFirestore(result.user, token);
    Sentry.captureMessage("Facebook Login successful");
    loginSuccess.value = true;
  } catch (signInError) {
    console.error("Error signing in with Facebook:", signInError);
    Sentry.captureException(signInError);
    errorMessage.value = "Facebook authentication failed. Please try again.";
  } finally {
    loading.value = false;
  }
};

const instagramBusinessLogin = () => {
  const instagramAuthUrl = "https://www.instagram.com/oauth/authorize";
  const params = new URLSearchParams({
    client_id: "839938541145136",
    redirect_uri: "https://chattersage.com/login",
    response_type: "code",
    scope: "business_basic,business_manage_comments",
  });
  window.location.href = `${instagramAuthUrl}?${params.toString()}`;
};

const signInWithEmail = async () => {
  if (!ENABLE_EMAIL_LOGIN) return;
  loading.value = true;
  errorMessage.value = "";
  try {
    await signInWithEmailAndPassword(firebaseAuth, email.value, password.value);
    Sentry.captureMessage("Email Login successful");
    loginSuccess.value = true;
  } catch (signInError) {
    console.error("Error signing in with email/password:", signInError);
    Sentry.captureException(signInError);
    errorMessage.value = "Email authentication failed. Please try again.";
  } finally {
    loading.value = false;
  }
};

const handleInstagramCallback = async () => {
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");
  if (code) {
    loading.value = true;
    errorMessage.value = "";
    try {
      const auth_ig_url = "https://chattersage.com/auth_ig";
      const response = await fetch(`${auth_ig_url}?code=${code}`);
      const data = await response.json();

      if (!response.ok) {
        if (data.errorType === "META_API_ERROR") {
          throw new Error(data.errorMessage);
        } else {
          throw new Error(
            data.errorMessage || "Failed to exchange code for token",
          );
        }
      }

      const { fire_token } = data;
      const userCredential = await signInWithCustomToken(
        firebaseAuth,
        fire_token,
      );
      await updateUserInFirestore(userCredential.user);
      window.history.replaceState({}, document.title, "/login");
      Sentry.captureMessage("Instagram Login successful");
      loginSuccess.value = true;
    } catch (signInError) {
      console.error("Error during Instagram login:", signInError);
      Sentry.captureException(signInError);
      errorMessage.value = signInError.message;
    } finally {
      loading.value = false;
    }
  }
};

onMounted(() => {
  handleInstagramCallback();
});

firebaseAuth.onAuthStateChanged((user) => {
  if (user) {
    setTimeout(() => {
      router.push("/");
    }, FORWARD_DELAY);
  }
});
</script>

<template>
  <div class="login-container">
    <h2>Login to {{ APP_NAME }}</h2>
    <div class="login-options">
      <div v-if="loading" class="loading-container">
        <div class="spinner"></div>
        <p>Authenticating...</p>
      </div>
      <div v-else-if="loginSuccess" class="success-message">
        <p>Login successful! Redirecting...</p>
      </div>
      <div v-else>
        <div class="login-buttons">
          <button id="sign-in-facebook" @click="signInWithFacebook">
            Login with Facebook
          </button>
          <button id="sign-in-instagram" @click="instagramBusinessLogin">
            Login with Instagram
          </button>
        </div>
        <div v-if="ENABLE_EMAIL_LOGIN" class="email-login">
          <h3>Login with Email</h3>
          <form @submit.prevent="signInWithEmail">
            <input v-model="email" type="email" placeholder="Email" required />
            <input
              v-model="password"
              type="password"
              placeholder="Password"
              required
            />
            <button type="submit">Login</button>
          </form>
        </div>
        <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-md);
  height: 100%;
}

.login-options {
  width: 100%;
  max-width: 300px;
}

.loading-container,
.success-message {
  text-align: center;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid var(--primary-color);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.login-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-buttons button,
.email-login button {
  width: 100%;
  margin: 0.5em 0;
  padding: 10px;
  font-size: 1em;
  cursor: pointer;
}

#sign-in-facebook {
  background-color: #1877f2;
  color: white;
}

#sign-in-instagram {
  background-color: #e1306c;
  color: white;
}

.email-login {
  margin-top: 20px;
}

.email-login input {
  width: 100%;
  margin-bottom: 10px;
  padding: 8px;
}

.email-login button {
  background-color: #4caf50;
  color: white;
}

.error-message {
  text-align: center;
  font-weight: bold;
}

.success-message {
  margin-top: 10px;
}
</style>
